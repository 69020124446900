/* TODO: remove this file */

html {
  height: 100%;
  overflow-x: hidden;
}

body {
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
}

html > body > div {
  height: 100%;
  width: 100%;
}

@font-face {
  font-family: aktiv-grotesk, sans-serif;
  font-weight: 400;
  src: url("./fonts/AktivGrotesk/AktivGrotesk-Regular.otf");
}

@font-face {
  font-family: GintoNormal;
  font-weight: bold;
  src: url("./fonts/GintoNormal/ABCGintoNormal-Bold-Trial.otf");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
  font-family: aktiv-grotesk, sans-serif;
  font-weight: 400;
}

input :focus {
  border-color: #000 !important;
  /* box-shadow: rgba(42, 67, 214, 0.692) 0px 2px 8px 0px !important; */
  box-shadow: #68f4c9;
  outline: none;
  border-width: 1px !important;
}

* :focus-visible {
  border-color: none !important;
  outline: none !important;
}

* :active {
  border-color: none !important;
  outline: none !important;
  box-shadow: none;
}
